import { css } from 'styled-components'

const sizes = {
  tablet: 768,
  phone: 576,
  contactUsSmall: 625,
  ourServicesTablet: 797,
  ourServicesSmall: 740,
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default media

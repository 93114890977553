import React, { useContext, useState, useEffect } from "react"
import { connect } from "react-redux"
import { useStaticQuery, Link, graphql, navigate } from "gatsby"
import styled, { css } from "styled-components"
import { NavigationContext } from "./navigation-context"
import media from "../utils/media"
import Button from "./button"
import Img from "gatsby-image/withIEPolyfill"
import { getCookie, setCookie } from "../utils/cookies"
import ResponsiveSpacing from "./responsive-spacing"
import ConnectedCartIcon from "./cart-icon"
import PromoSticker from "./promo-sticker"
import postPath from "../utils/post-path"
import SVG from "./svg"

const MENU_FIRE_TIME = 200

const NavigationContainer = styled.div`
  position: fixed;
  z-index: 100;
  left: 0px;
  right: 0px;
  top: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    min-height: 0px;
    left: 0;
    right: 0;
  }

  .top-line {
    background-color: ${(p) => p.theme.DarkBlue};
    text-align: center;
    color: ${(p) => p.theme.White};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;

    @media (max-width: 768px) {
      display: ${(p) => (p.open ? "none" : "flex")};
    }

    p {
      padding: 4px 0;
      margin: 0;
      font-size: 1.1em;
      letter-spacing: 1px;
      line-height: 1.2em;
      @media (max-width: 768px) {
        font-size: 1em;
      }
    }
  }

  .low-line {
    display: flex;
    background-color: ${(props) => props.theme.White};
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;

    transition: padding 600ms;
    padding: 0;
    width: 100%;
    @media (max-width: 1450px) {
      padding: 0px 50px;
    }
    @media (max-width: 768px) {
      padding: 0 50px;
      margin: 0;
    }
    @media (max-width: 580px) {
      padding: 0px 10px;
    }

    @media (max-width: 768px) {
      display: ${(p) => (p.open ? "none" : "flex")};
      justify-content: space-between;
    }

    .mobile-nav-cart-icon {
      position: absolute;
      right: 100px;
      top: 25px;
      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .logo {
    top: 0px;
    position: relative;
    z-index: 3;
    max-width: 140px;
  }

  > .nav-inner {
    padding: 0;
    position: relative;
    max-width: 100%;
    margin: 0px auto 0 auto;
    transition: background 400ms;
    background: ${(props) => props.theme.White};
    z-index: 3;

    @media (max-width: 768px) {
      margin-top: 0px;
      border-radius: 0px;
    }
  }

  > a {
    font-size: 1.4em;
  }
`

const PageTitle = styled(Link)`
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 900;
  font-size: 28px;
`

const SocialLinks = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 150px;
    width: 200px;
    position: relative;
    left: 0;
    right: 0;
  }
`

const DarkPageTitle = styled(PageTitle)`
  position: relative;
  padding-top: 20px;
`

const NavigationIcon = styled.div`
  position: absolute;
  top: 54px;
  right: 20px;
  display: none;
  height: 20px;
  width: 31px;
  margin: 0;
  z-index: 200;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    right: 20px;
  }

  ${({ open }) => {
    if (open) {
      return css`
        & > .icon-bar {
          background-color: white;
          &:nth-child(1) {
            transform: rotate(45deg) translateY(4px) translateX(3px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translateY(-6px) translateX(4px);
          }
        }
      `
    } else {
      return css`
        &:hover > .icon-bar {
          &:nth-child(1) {
            transform: translateY(-1px);
          }

          &:nth-child(2) {
            width: 15px;
            transform: translateX(3px);
          }

          &:nth-child(3) {
            transform: translateY(1px);
          }
        }
      `
    }
  }}
`
const NavigationIconBar = styled.div`
  height: 2px;
  width: 22px;
  background: ${(props) => props.theme.DarkBlue};
  margin: 4px 0;
  border-radius: 3.5px;
  transition: 0.5s cubic-bezier(0.86, -0.91, 0.13, 2.26);
`

const NavigationOverlay = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 0 0 ${(props) => props.theme.DarkBlue},
    0 0 0 0 ${(props) => props.theme.DarkBlue};
  background-color: ${(props) => props.theme.DarkBlue};
  z-index: 1;

  ${({ open }) => {
    if (open) {
      return css`
        box-shadow: 0 0 0 150vw ${(props) => props.theme.DarkBlue},
          0 0 0 150vh ${(props) => props.theme.DarkBlue};
        border-radius: 0;
      `
    }
  }}
`

const NavigationItemsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  font-size: 1.2em;
  opacity: 0;
  transition: 0.5s ease-in-out;
  pointer-events: none;
  background-color: ${(props) => props.theme.DarkBlue};
  ${({ open }) => {
    if (open) {
      return css`
        opacity: 1;
        pointer-events: all;
      `
    }
  }}
`

const NavigationList = styled.ul`
  font-size: 2em;
  padding: 0;
  margin: 20px 0;
`

const NavigationItem = styled.li`
  list-style: none;

  .mobileWork {
    text-decoration: none;
    cursor: pointer;
    padding: 4px 0;
    margin: 0;
    text-align: center;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: bold;
    display: block;
    position: relative;
    transition: font-size 200ms;
    color: ${(props) => props.theme.DarkBlue};
    font-family: "interstate-compressed";

    > span.front {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 40px;
      margin-left: 35px;
      position: relative;
    }

    svg {
      position: relative;
      float: right;
      margin: 3px 0 0 10px;
    }

    @media (max-width: 768px) {
      color: ${(props) => props.theme.White};
    }
  }
`

const NavigationLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  padding: 0px 0;
  margin: 10px 0;
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 1px;
  font-weight: bold;
  transition: font-size 200ms;
  color: ${(props) => props.theme.DarkBlue};
  font-size: 2em;
  font-family: "interstate-compressed";

  span.front {
    z-index: 2;
    display: inline-block;
    position: relative;
  }

  svg {
    position: relative;
    float: right;
    margin: 3px 0 0 10px;
  }

  ${media.tablet`
    font-size: 1.4em;
    margin: 0;
    color: ${(props) => props.theme.White};
  `}

  > span.underline {
    display: block;
    position: absolute;
    background: ${(props) => props.theme.DarkBlue};
    left: 50%;
    bottom: 0%;
    width: 0%;
    height: 2px;
    margin-left: 0;
    transition: width 0.2s, margin-left 0.2s;
  }

  &.active.animate span.underline,
  &:hover span.underline {
    display: block;
    width: 100%;
    margin-left: -50%;
    transition-delay: 0.3s;
  }
`

const DesktopNavigation = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: min-width 0.3s;
  min-width: 640px;

  @media (max-width: 1080px) {
    min-width: 640px;
  }
  @media (max-width: 950px) {
    min-width: 540px;
  }
  @media (max-width: 860px) {
    min-width: 440px;
  }
  @media (max-width: 800px) {
    min-width: 390px;
  }

  .nav-cart-icon {
    position: absolute;
    right: 30px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const DesktopNavigationItem = styled.div`
  padding: 0;
  text-transform: uppercase;
  display: flex;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.DarkBlue};
  align-items: center;
`

const handleClick = (evt, location, setOpenStatus) => {
  evt.preventDefault()
  setOpenStatus(false)
  navigate(location)
}

const DButton = styled(Link)`
  background-color: ${(props) => props.theme.DarkBlue};
  color: ${(props) => props.theme.White};
  padding: 13px 20px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 14px;
  @media (max-width: 768px) {
    display: none;
  }

  white-space: nowrap;
  border: 3px solid ${(props) => props.theme.DarkBlue};
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  position: relative;

  @media (max-width: 768px) {
    margin: 20px 0;
  }

  > span {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: ${(props) => props.theme.ButtonHover};
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transition: 0.3s;
    z-index: -1;
    transform-origin: 50% 50%;
  }

  &:hover span {
    transform: translateZ(0) scale(150);
  }
`

const MobileButton = styled(DButton)`
  ${media.tablet`
    display: block;
    background-color: ${(props) => props.theme.White};
    color: ${(props) => props.theme.DarkBlue};
  `}
`

const SocialLink = styled.a`
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.DarkBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0;
`

const MobileSocialLink = styled(SocialLink)`
  color: ${({ theme }) => theme.White};
`

const MobileNavMenuStyled = styled.div`
  @media (max-width: 768px) {
    display: block !important;
    opacity: 1 !important;
    overflow: hidden;
    max-height: ${({ open }) => (open ? "134px" : 0)};
    padding: 0;
    transition: max-height 400ms;
    top: 0px;
    margin-left: 0px;
    text-align: center;
    position: relative;
    background-color: ${({ theme }) => theme.DarkBlue};
  }
`

const NavMenuStyled = styled.div`
  padding: 10px 20px;
  text-align: left;
  border-radius: 20px;
  border-top-left-radius: 0;
  background-color: ${({ theme }) => theme.White};
  width: 270px;
  margin-left: -20px;
  position: absolute;
  z-index: 1;
  top: 35px;
  transition: display 0ms, opacity 400ms, width 0.3s, padding 0.3s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  display: ${({ open }) => (open ? "block" : "none")};

  @media (max-width: 1080px) {
    width: 205px;
    margin-left: -10px;
    padding: 10px;
  }
  @media (max-width: 950px) {
    width: 155px;
    margin-left: -8px;
    padding: 8px;
  }

  > span {
    margin: -50px 0 0 -20px;
    height: 50px;
    display: block;
    position: absolute;
    transition: margin-left 300ms;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${({ theme }) => theme.White};
    z-index: 0;
    width: 155px;

    @media (max-width: 1080px) {
      width: 120px;
      margin-left: -10px;
    }

    @media (max-width: 950px) {
      width: 100px;
      margin-left: -8px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`

const NavigationMenuLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  display: block;
  color: ${(props) => props.theme.DarkBlue};
  position: relative;
  padding: 10px 0;
  z-index: 2;
`

const NavigationSubMenuLink = styled(NavigationMenuLink)`
  @media (max-width: 768px) {
    color: ${({ theme }) => theme.LightBlue} !important;
    text-align: center;
    padding: 0;
    font-size: 0.85em;
    width: 100%;
  }
`

const MobileNavMenu = ({ menu_items, open }) => {
  return (
    <MobileNavMenuStyled open={open}>
      <span />
      {menu_items.map((item, i) => (
        <NavigationSubMenuLink
          to={item.link_location || item.page_link}
          key={i}
        >
          {item.display_text || item.page_label}
        </NavigationSubMenuLink>
      ))}
    </MobileNavMenuStyled>
  )
}

const MyAccountStyled = styled(Link)`
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.DarkBlue};
  text-decoration: none;
  font-size: 1.3em;
  font-weight: bold;
  letter-spacing: 1px;
  width: 120px;
  position: absolute;
  right: 40px;

  &:hover {
    text-decoration: underline;
  }
`

const MyAccount = ({ fields, user }) => {
  if (!!user && user.accessToken) {
    return (
      <MyAccountStyled to="/my-account/">
        <span>{fields.header.account_label}</span>
      </MyAccountStyled>
    )
  } else {
    return <MyAccountStyled to="/login/">LOGIN</MyAccountStyled>
  }
}

const ConnectedMyAccount = connect((state) => state)(MyAccount)

const NavMenu = ({ menu_items, open }) => {
  return (
    <NavMenuStyled open={open}>
      <span />
      {menu_items.map((item, i) => (
        <NavigationSubMenuLink to={item.link_location} key={i}>
          {item.display_text}
        </NavigationSubMenuLink>
      ))}
    </NavMenuStyled>
  )
}

const DropDownMenuStyled = styled.div`
  position: absolute;
  top: 90px;
  left: 0px;
  right: 0px;
  padding: 20px 0 0px 0;
  background: white;
  z-index: 2;

  > .inner {
    max-width: 100%;
    background: white;
    margin-bottom: 10px;
  }

  > .inner .inner2 {
    display: flex;
    flex-wrap: nowrap;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 100px;
  }

  h4 {
    font-size: 1.2em;
    color: ${(p) => p.theme.Blue};
    max-width: 140px;
    padding: 10px 0;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    text-transform: uppercase;
  }

  .ddMenuItem {
    min-width: 110px;
    max-width: 220px;
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .image {
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .ddMenuButtons {
    height: 110px;
    min-width: 160px;
    max-width: 220px;
    .spacer {
      margin-bottom: 12px;
    }
  }

  .bottomShadow {
    box-shadow: 0 0 8px ${(p) => p.theme.Grey};
    position: absolute;
    bottom: 0;
    height: 10px;
    width: 100%;
    z-index: -1;
  }

  .gatsby-image-wrapper {
    width: 80px;
  }

  .end {
    color: ${(p) => p.theme.DarkBlue};
  }
`

const DropDownMenu = ({ onMouseOver, onMouseOut, activeMenu, children }) => {
  return (
    <DropDownMenuStyled
      tabIndex="0"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseOut}
      onFocus={onMouseOver}
      onBlur={onMouseOut}
      className={`menu ${activeMenu}`}
    >
      <div className="inner">
        <div className="inner2">{children}</div>
      </div>
      <div className="bottomShadow" />
    </DropDownMenuStyled>
  )
}

const Navigation = ({ data, style = {}, cart }) => {
  const [navMenuState, setNavMenuState] = useState(-1)
  const [hoverMenu, setHoverMenu] = useState("about")
  const [scrollingState, setScrollingState] = useState(false)
  const { navigationOpen, toggleNavigation } = useContext(NavigationContext)

  const page = data.page.edges[0].node
  const fields = page.acf

  const flavors = data.flavors.edges
    .map((e) => e.node)
    .filter((n) => {
      if (n.acf.flavor.not_for_sale) return
      return fields.navigation.menu_flavors
        .map((mf) => mf.wordpress_id)
        .includes(n.wordpress_id)
    })

  const merch = data.merch.edges
    .map((e) => e.node)
    .filter((n) => {
      if (n.acf.not_for_sale) return
      return fields.navigation.menu_merch
        .map((mf) => mf.wordpress_id)
        .includes(n.wordpress_id)
    })

  const about = fields.navigation.menu_about

  const menu_items = fields.navigation.nav_item
  const menu_items_about = fields.navigation.menu_about

  const menu_items_flavors = [
    {
      page_label: "ALL FLAVORS",
      page_link: "/flavors/",
    },
    {
      page_label: "SEE RECIPES",
      page_link: "/recipes/",
    },
  ]

  const menuOver = (slug) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setHoverMenu(slug)
    }, MENU_FIRE_TIME)
  }
  const menuOut = (e) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setHoverMenu("")
    }, MENU_FIRE_TIME)
  }

  const hoverMenuHTML = (activeMenu) => {
    if (activeMenu === "flavors")
      return (
        <DropDownMenu
          onMouseOver={(e) => menuOver(activeMenu)}
          onMouseOut={menuOut}
          activeMenu={activeMenu}
        >
          <div className="ddMenuButtons">
            <Button to="/flavors/" className="spacer">
              All Flavors
            </Button>
            <Button to="/recipes/">See Recipes</Button>
          </div>
          {flavors.map((m, index) => {
            const promotion = m.acf.flavor.promotion
            return (
              <div className="ddMenuItem" key={index}>
                <Link
                  to={postPath("/flavors/", m.acf.flavor.name)}
                  className="image"
                >
                  <PromoSticker
                    className="topLeft smaller"
                    style={{ marginLeft: 20 }}
                    promotion={promotion}
                  />
                  <Img
                    height={90}
                    fixed={
                      m.acf.flavor.carton_image.localFile.childImageSharp.fixed
                    }
                  />
                  <h4 dangerouslySetInnerHTML={{ __html: m.acf.flavor.name }} />
                </Link>
              </div>
            )
          })}
        </DropDownMenu>
      )
    if (activeMenu === "merch") {
      return (
        <DropDownMenu
          onMouseOver={(e) => menuOver(activeMenu)}
          onMouseOut={menuOut}
          activeMenu={activeMenu}
        >
          <div className="ddMenuButtons">
            <Button to="/merch/">Shop Merch</Button>
          </div>
          {merch.map((m, index) => {
            const promotion = m.acf.promotion
            const ns = m.acf.name.split(" ")
            const first = ns.slice(0, ns.length - 1).join(" ")
            const last = ns.slice(ns.length - 1, ns.length)
            const nameHTML = (
              <span>
                {first}
                <br />
                <span className="end">{last}</span>
              </span>
            )
            return (
              <div className="ddMenuItem" key={index}>
                <Link to={postPath("/merch/", m.acf.name)} className="image">
                  <PromoSticker
                    promotion={promotion}
                    className="topLeft smaller"
                  />
                  <Img
                    height={90}
                    fixed={
                      m.acf.images[0].image.localFile.childImageSharp.fixed
                    }
                  />
                  <h4>{nameHTML}</h4>
                </Link>
              </div>
            )
          })}
        </DropDownMenu>
      )
    }
    if (activeMenu === "about-us")
      return (
        <DropDownMenu
          onMouseOver={(e) => menuOver(activeMenu)}
          onMouseOut={menuOut}
          activeMenu={activeMenu}
        >
          {about.map((m, index) => {
            return (
              <div className="ddMenuItem" key={index}>
                <Link to={m.page_link} className="image">
                  {m.page_thumb ? (
                    <Img
                      height={90}
                      fixed={m.page_thumb.localFile.childImageSharp.fixed}
                    />
                  ) : (
                    ""
                  )}
                  <h4 dangerouslySetInnerHTML={{ __html: m.page_label }} />
                </Link>
              </div>
            )
          })}
        </DropDownMenu>
      )
  }
  let timeout
  return (
    <NavigationContainer open={navigationOpen} style={{ ...style }}>
      <NavigationOverlay open={navigationOpen} />
      <NavigationItemsContainer open={navigationOpen}>
        <div>
          <DarkPageTitle to="/">
            <img
              src={fields.header.logo.source_url}
              alt={fields.header.logo_alt_text}
            />
          </DarkPageTitle>
        </div>
        <NavigationList>
          {menu_items.map((item, i) => {
            const mobileMenuHTML = (menu_items) => (
              <MobileNavMenu
                menu_items={menu_items}
                open={navMenuState == i}
                toggleNavigation={toggleNavigation}
              />
            )

            let thisMenu
            if (item.display_text === "FLAVORS")
              thisMenu = mobileMenuHTML(menu_items_flavors)
            else thisMenu = mobileMenuHTML(menu_items_about)
            return (
              <NavigationItem key={i}>
                {["FLAVORS", "ABOUT US"].includes(item.display_text) ? (
                  <div className="mobileWork">
                    <span
                      className="front"
                      onClick={(e) => {
                        if (navMenuState === i) setNavMenuState(-1)
                        else setNavMenuState(i)
                      }}
                    >
                      {item.display_text}
                      <SVG
                        style={{
                          zIndex: 2,
                          transform: `scaleY(${navMenuState === i ? -1 : 1})`,
                        }}
                        name="DownArrow"
                        color="White"
                      />
                    </span>
                    {thisMenu}
                  </div>
                ) : (
                  <NavigationLink
                    to={item.link_location}
                    onClick={(e) =>
                      handleClick(e, item.link_location, toggleNavigation)
                    }
                  >
                    {item.display_text}
                  </NavigationLink>
                )}
              </NavigationItem>
            )
          })}
        </NavigationList>
        <SocialLinks>
          {fields.social.map((sl, i) => {
            return (
              <MobileSocialLink href={sl.link} key={i}>
                <img src={sl.icon.source_url} alt={sl.alt_text} />
              </MobileSocialLink>
            )
          })}
        </SocialLinks>
      </NavigationItemsContainer>
      <header
        className={`inner nav-inner ${scrollingState ? "scrolling" : ""}`}
      >
        <div
          className="top-line"
          dangerouslySetInnerHTML={{ __html: fields.header.promo_banner }}
        />
        <div className="low-line">
          <div className="logo">
            <PageTitle to="/">
              <img
                src={fields.header.logo.source_url}
                alt={fields.header.logo_alt_text}
              />
            </PageTitle>
          </div>
          <div className="mobile-nav-cart-icon">
            <ConnectedCartIcon />
          </div>
          <DesktopNavigation className="navItems">
            {menu_items.map((item, i) => {
              const slug = item.display_text.toLowerCase().replace(/\s/g, "-")
              return (
                <DesktopNavigationItem key={i}>
                  <NavigationLink
                    className={slug}
                    to={item.link_location}
                    activeClassName="active"
                    onMouseOver={(e) => menuOver(slug)}
                    onMouseOut={menuOut}
                  >
                    {item.display_text}
                    <span className="underline" />
                  </NavigationLink>
                </DesktopNavigationItem>
              )
            })}
            <ConnectedMyAccount fields={fields} />
            <div className="nav-cart-icon">
              <ConnectedCartIcon />
            </div>
          </DesktopNavigation>
        </div>
        <NavigationIcon
          open={navigationOpen}
          onClick={() => {
            toggleNavigation()
          }}
        >
          <NavigationIconBar className="icon-bar" />
          <NavigationIconBar className="icon-bar" />
          <NavigationIconBar className="icon-bar" />
        </NavigationIcon>
        {hoverMenuHTML(hoverMenu)}
      </header>
    </NavigationContainer>
  )
}

const NavigationWData = (props) => {
  const data = useStaticQuery(graphql`
    query {
      flavors: allWordpressWpFlavors(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            title
            wordpress_id
            acf {
              flavor {
                name
                not_for_sale
                promotion
                carton_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 110) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      merch: allWordpressWpMerch {
        edges {
          node {
            id
            wordpress_id
            title
            acf {
              name
              not_for_sale
              promotion
              images {
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 110) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      page: allWordpressPage(filter: { slug: { eq: "shared-layout" } }) {
        edges {
          node {
            acf {
              header {
                promo_banner
                logo {
                  source_url
                }
                account_label
                logo_alt_text
              }
              social {
                icon {
                  source_url
                }
                alt_text
                link
              }
              navigation {
                nav_item {
                  display_text
                  link_location
                }
                menu_merch {
                  wordpress_id
                }
                menu_flavors {
                  wordpress_id
                }
                menu_about {
                  page_label
                  page_thumb {
                    localFile {
                      childImageSharp {
                        fixed(width: 110) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                  page_link
                }
              }
            }
          }
        }
      }
    }
  `)
  return <Navigation data={data} {...props} />
}

const ConnectedNavigation = connect((state) => state)(NavigationWData)

export default ConnectedNavigation

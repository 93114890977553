import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { Link } from "gatsby"
import Inner from "./inner"
import RoughEdge from "./rough-edge"
import ResponsiveSpacing from "./responsive-spacing"

const FooterStyled = styled.div`
  position: relative;
  z-index: 0;
  font-family: "interstate-compressed";
  background: ${(p) => p.theme.DarkBlue};
  color: ${(p) => p.theme.White};
  text-align: center;
  min-height: 200px;

  .footer-logo {
    position: realtive;
    margin: 20px auto;
    width: 170px;
    @media (max-width: 768px) {
      padding-top: 20px;
      margin: 0px auto;
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }

  a {
    color: ${(p) => p.theme.White};
  }
`

const CopyrightStyled = styled.div`
  font-size: 1.2em;
  line-height: 2.3em;
  font-family: "Montserrat";
  padding-bottom: 40px;
  letter-spacing: 0.15em;
  background: ${(p) => p.theme.VDarkBlue};
  position: relative;

  @media (max-width: 768px) {
    padding-bottom: 70px;
  }
`

const Copyright = ({ fields }) => {
  const theme = useContext(ThemeContext)
  return (
    <CopyrightStyled>
      <RoughEdge className="top" color={theme.VDarkBlue} />
      <ResponsiveSpacing>
        <div className="text">
          &copy; {new Date().getFullYear()}
          {` `}
          <span
            dangerouslySetInnerHTML={{
              __html: fields.copyright,
            }}
          />
        </div>
      </ResponsiveSpacing>
    </CopyrightStyled>
  )
}

const FooterNavStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  max-width: 860px;
  margin: 30px auto;
  a {
    font-size: 20px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    a {
      display: inline-block;
      text-align: center;
      width: 100%;
      margin: 10px 0;
    }
  }
`

const FooterNav = ({ fields }) => {
  const { footer_links } = fields
  return (
    <FooterNavStyled>
      {footer_links.map((link, i) => {
        return (
          <Link key={i} to={link.link_location}>
            {link.display_text}
          </Link>
        )
      })}
    </FooterNavStyled>
  )
}

const SocialIconsStyled = styled.div`
  max-width: 200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;

  img {
    width: 30px;
  }
`

const SocialIcons = ({ fields }) => {
  return (
    <SocialIconsStyled>
      {fields.map((icon, i) => {
        return (
          <a key={i} href={icon.link} target="_blank" rel="noopener noreferrer">
            <img alt={icon.alt_text} src={icon.icon.source_url} />
          </a>
        )
      })}
    </SocialIconsStyled>
  )
}

const AvailableAtStyled = styled.div`
  position: absolute;
  right: 60px;
  top: 30px;
  width: 200px;
  height: 60px;
  z-index: 2;

  @media (max-width: 768px) {
    top: auto;
    right: auto;
    position: relative;
    margin: 0 auto;
    padding: 0px;
    width: 200px;
    height: 120px;
  }

  .img {
    height: 60px;
  }

  h4 {
    font-family: 'Montserrat';
    font-size: 1.4em;
    letter-spacing: 1px;

  }

  .these-stores {
    display flex;
    justify-content: space-between;
  }
`

const AvailableAt = ({ fields }) => {
  return (
    <AvailableAtStyled>
      <h4>{fields.available_title}</h4>
      <div className="these-stores">
        {fields.available_at.map((a, i) => (
          <img
            className="img"
            key={i}
            src={a.icon.url.source_url}
            alt={a.alt_text}
          />
        ))}
      </div>
    </AvailableAtStyled>
  )
}

const Footer = ({ fields }) => {
  const theme = useContext(ThemeContext)
  return (
    <FooterStyled>
      <RoughEdge className="top" color={theme.DarkBlue} />
      <ResponsiveSpacing>
        <Inner>
          <img
            className="footer-logo"
            alt={"Nadamoo Logo"}
            src={fields.footer.logo.source_url}
          />
          <FooterNav fields={fields.footer} />
          <SocialIcons fields={fields.social} />
        </Inner>
        <AvailableAt fields={fields.footer} />
      </ResponsiveSpacing>
      <Copyright fields={fields.footer} />
    </FooterStyled>
  )
}

export default Footer

import React from "react"
import styled from "styled-components"

const CartItemStyled = styled.div`
  display: grid;
  grid-template-columns: 116px 1fr;
  padding: 10px 0;

  .image {
    background-color: ${(p) => p.theme.LightBlue};
    width: 106px;
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 106px;
    max-height: 106px;
  }

  .info {
    color: ${(p) => p.theme.DarkGrey};
    font-size: 1em;
  }

  ul {
    text-transform: uppercase;
    font-size: 0.9em;
    list-style: none;
    margin: 0;
    padding: 0;
    color: ${(p) => p.theme.DarkGrey};
  }

  h3 {
    color: ${(p) => p.theme.DarkBlue};
    font-family: "interstate-compressed";
    margin: 0;
    line-height: 24px;
    text-transform: uppercase;
    padding-right: 68px;
  }

  h4 {
    color: ${(p) => p.theme.Blue};
    margin: 4px 0;
    font-size: 1.4em;
    text-transform: uppercase;
  }

  .sub-head {
    margin: 0;
    text-transform: uppercase;
    line-height: 1em;
    color: ${(p) => p.theme.Blue};
  }

  .price {
    font-size: 1.8em;
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
    position: absolute;
    text-align: right;
    right: 0;
  }

  .remove {
    display: block;
    font-size: 0.6em;
    text-transform: uppercase;
    color: ${(p) => p.theme.Blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .action {
    font-family: "interstate-compressed";
  }
`

const CartItem = ({ product, index, dispatch }) => {
  const price = product.price
  const image = product.image
  let lineItems = product.lineItems
  if (product.pints) lineItems = product.pints.map((pr) => pr.name)
  let lineItemsHTML = ""
  if (lineItems) {
    const itemList = (
      <ul>
        {lineItems.map((i, index) => {
          return <li key={index}>{i}</li>
        })}
      </ul>
    )
    lineItemsHTML = itemList
  }
  return (
    <CartItemStyled>
      <div className="image">
        <img src={image} alt={product.name} />
      </div>
      <div className="info">
        <h3>{product.name}</h3>
        {product.short_info ? <h4>{product.short_info}</h4> : ""}
        {lineItemsHTML}
      </div>
      <div className="price">
        &#36;{price}
        <a
          className="remove"
          onClick={(e) => {
            e.preventDefault()
            dispatch({ type: "REMOVE_FROM_CART", removeIndex: index })
          }}
          href="#"
        >
          REMOVE
        </a>
      </div>
    </CartItemStyled>
  )
}

export default CartItem

import React, { useContext, useEffect, useState, useRef } from "react"
import styled, { ThemeContext } from "styled-components"
import SVG from "./svg"
import { connect } from "react-redux"
import Button from "./button"
import { Link, useStaticQuery, graphql } from "gatsby"

const CART_AUTO_CLOSE = 6000
const MOBILE_BUTTON_WIDTH = 125
const DESKTOP_BUTTON_WIDTH = 150

let timeout

const PacksStyled = styled.div`
  position: fixed;
  transition: transform 300ms;
  left: 0;
  right: 0;
  height: 290px;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.LightBlue};
  border-top: 5px solid ${(p) => p.theme.VDarkBlue};
  bottom: -290px;
  transform: translate3d(0, 0px, 0);
  z-index: 3;

  &:hover {
    z-index: 5;
  }

  &.open {
    transform: translate3d(0, -290px, 0px);
  }

  .addButton {
    font-size: 18px;
    min-height: 40px;

    @media (max-width: 768px) {
      margin: 5px 0;
      font-size: 18px;
      max-width: 120px;
      padding: 7px 7px;

      & > .svg {
        margin-left: 0;
        left: 3px;
      }
    }
  }

  .packArea {
    margin: 20px 0 0 0;
    width: 100%;
    height: 265px;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
      margin: 5px 0 0 0;
      height: 285px;
    }
  }

  .topLine {
    p {
      margin: 5px 0 0;
      color: ${(p) => p.theme.VDarkBlue};
      text-align: center;
      font-size: 1.4em;
    }
  }

  .packTopper {
    cursor: pointer;
    background: ${(p) => p.theme.VDarkBlue};
    position: absolute;
    height: 40px;
    top: -40px;
    color: ${(p) => p.theme.White};
    font-size: 1.4em;
    width: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    left: 50%;
    margin-left: -150px;

    > .svg {
      height: 15px;
      margin-left: 15px;
      transform: scaleY(${(p) => (p.open ? 1 : -1)});
    }

    @media (max-width: 768px) {
      height: 60px;
      top: -60px;
    }
  }

  .sh {
    margin: 5px 0;
    font-size: 0.9em;
  }

  .botLine {
    position: absolute;
    width: 100%;
    font-weight: bold;
    background: ${(p) => p.theme.DarkBlue};
    padding: 8px 0;
    text-align: center;
    font-size: 1.4em;
    color: ${(p) => p.theme.White};
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: ${(p) => p.theme.White};
    }

    @media (max-width: 768px) {
      font-size: 1.1em;
      padding: 8px 0 30px 0;
    }
  }

  .price {
    font-size: 2.4em;
    color: ${(p) => p.theme.DarkBlue};
    text-align: center;
    font-weight: bold;
  }

  .flatRate {
    font-size: 1.3em;
    margin: 5px 0;
    color: ${(p) => p.theme.DarkBlue};
    text-align: center;
    @media (max-width: 768px) {
      width: 90px;
      margin: 0;
    }
  }

  .midLine {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1185px;
    height: 140px;
  }

  .bothHolder {
    width: 100%;
    max-width: 1185px;
    position: absolute;
    height: 166px;
    top: 20px;
    left: 5px;

    @media (max-width: 1160px) {
      top: 15px;
    }

    @media (max-width: 768px) {
      top: 5px;
      left: 3%;
    }

    @media (max-width: 768px) {
      left: 5px;
      margin-right: 130px;
      right: 130px;
    }

    .empties {
      z-index: 1;
    }

    .fulls {
      z-index: 2;
    }

    .empties,
    .fulls {
      position: absolute;
      width: auto;
      left: 0;
      right: 0;
      top: 0px;

      @media (max-width: 768px) {
        top: 0;
      }
    }
  }

  .remove {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: ${(p) => p.theme.DarkBlue};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    color: ${(p) => p.theme.White};
    opacity: 0;
    transition: opacity 200ms;
    font-family: sans-serif;
  }

  @media (max-width: 768px) {
    .remove {
      height: 20px;
      width: 20px;
      margin-top: -5px;
      margin-left: -5px;
      font-size: 15px;
    }
  }
  @media (max-width: 580px) {
    .remove {
      height: 20px;
      width: 20px;
      margin-top: -7px;
      margin-left: -7px;
      font-size: 10px;
    }
  }
  @media (min-width: 769px) {
    .remove {
      height: 34px;
      width: 34px;
      margin-top: -10px;
      margin-left: -10px;
      font-size: 25px;
    }
  }

  .item {
    max-width: 140px;
    height: auto;
    cursor: pointer;
    display: block;
    transition: all 400ms;

    img {
      max-width: 103px;
      width: inherit;
      text-align: center;
    }

    &.full {
      opacity: 1;
    }

    &.empty {
      display: block;
      transform: scaleY(0.95);
      img {
        transform: scaleY(0.95);
      }
    }

    &:hover .remove {
      opacity: 1;
    }
  }

  .full {
    top: 0px;
  }

  @media (max-width: 1140px) {
    .sized-9 {
      .item {
        width: 90px;
      }
    }
  }

  @media (max-width: 1020px) {
    .sized-9 {
      .item {
        width: 80px;
      }
    }
  }

  @media (max-width: 920px) {
    .sized-9 {
      .item {
        width: 70px;
      }
    }

    .sized-5 {
      .item {
        width: 103px;
      }
    }
  }

  @media (max-width: 768px) {
    .sized-9 {
      .item {
        width: 76px;
      }
    }

    .sized-5 {
      .item {
        width: 72px;
      }
    }
  }

  @media (max-width: 580px) {
    .sized-9 {
      .item {
        width: 58px;
      }
    }

    .sized-5 {
      .item {
        width: 72px;
      }
    }
  }

  @media (max-width: 400px) {
    .sized-9 {
      .item {
        width: 50px;
      }
    }

    .sized-5 {
      .item {
        width: 72px;
      }
    }
  }

  .buttonHolder {
    position: absolute;
    width: 145px;
    top: 0;
    right: 5px;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 120px;
    }
  }
  .item {
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: absolute;
    top: 0px;
    min-height: 40px;
  }

  .p1 {
    transition-delay: 0ms;
    z-index: 1;
  }
  .p2 {
    transition-delay: 100ms;
    z-index: 2;
  }
  .p3 {
    transition-delay: 150ms;
    z-index: 3;
  }
  .p4 {
    transition-delay: 200ms;
    z-index: 4;
  }
  .p5 {
    transition-delay: 250ms;
    z-index: 5;
  }
  .p6 {
    transition-delay: 300ms;
    z-index: 6;
  }
  .p7 {
    transition-delay: 350ms;
    z-index: 7;
  }
  .p8 {
    transition-delay: 400ms;
    z-index: 8;
  }
  .p9 {
    transition-delay: 450ms;
    z-index: 9;
  }
`

const Pint = ({
  container,
  item,
  i,
  itemPos,
  itemPos9,
  flavorKeys,
  dispatch,
  mode,
}) => {
  const ww = typeof window !== "undefined" ? window.innerWidth : 0
  const mobile = ww < 768
  let x, y
  if (mode === 5 && i >= 5) {
    // Hide extras
    x =
      (container.width -
        (mobile ? MOBILE_BUTTON_WIDTH : DESKTOP_BUTTON_WIDTH)) /
      2
    y = -200
  } else if (mode === 9) {
    x = item
      ? itemPos9[i].x
      : (container.width -
          (mobile ? MOBILE_BUTTON_WIDTH : DESKTOP_BUTTON_WIDTH)) /
        2
    y = item ? itemPos9[i].y : -200
  } else {
    x = itemPos[i].x
    y = itemPos[i].y
  }

  const style = { transform: `translate(${x}px, ${y}px)` }
  return (
    <div
      className={`item full p${i + 1}`}
      style={style}
      key={i}
      onClick={(e) => {
        dispatch({ type: "REMOVE_FROM_PACK", removeIndex: i })
      }}
    >
      <div className="remove">&times;</div>
      <img
        className="carton"
        src={item ? flavorKeys[item.name] : ""}
        alt={item ? item.name : ""}
      />
    </div>
  )
}

const Items = ({ items, mode, container, flavorKeys, dispatch, domRef }) => {
  const ww = typeof window !== "undefined" ? window.innerWidth : 0
  const mobile = ww < 768
  const spaceBetween = 2
  const mostOnARow = mobile ? (mode === 9 ? 5 : 3) : mode
  const padding = mostOnARow - 1 * spaceBetween
  const width =
    (container.width -
      (mobile ? MOBILE_BUTTON_WIDTH : DESKTOP_BUTTON_WIDTH) -
      padding) /
    mostOnARow
  const height = container.height / 2 - padding

  const halfWidth = width / 2
  const yPos = mobile ? height + spaceBetween : 0
  const xPos = mobile ? halfWidth : 0
  const xWidth = width + spaceBetween
  const itemPos9 = [
    {
      x: 0,
      y: 0,
    },
    {
      x: xWidth,
      y: 0,
    },
    {
      x: xWidth * 2,
      y: 0,
    },
    {
      x: xWidth * 3,
      y: 0,
    },
    {
      x: xWidth * 4,
      y: 0,
    },
    {
      x: xPos + xWidth * (mobile ? 0 : 5),
      y: yPos,
    },
    {
      x: xPos + xWidth * (mobile ? 1 : 6),
      y: yPos,
    },
    {
      x: xPos + xWidth * (mobile ? 2 : 7),
      y: yPos,
    },
    {
      x: xPos + xWidth * (mobile ? 3 : 8),
      y: yPos,
    },
  ]

  const itemPos = [
    {
      x: 0,
      y: 0,
    },
    {
      x: xWidth,
      y: 0,
    },
    {
      x: xWidth * 2,
      y: 0,
    },
    {
      x: xPos + xWidth * (mobile ? 0 : 3),
      y: yPos,
    },
    {
      x: xPos + xWidth * (mobile ? 1 : 4),
      y: yPos,
    },
  ]

  const arrayFromNumber = Array.apply(null, Array(9))
  const empties = arrayFromNumber.map((_, i) => {
    const ww = typeof window !== "undefined" ? window.innerWidth : 0
    const mobile = ww < 768
    let x, y
    if (mode === 5 && i >= 5) {
      x =
        (container.width -
          (mobile ? MOBILE_BUTTON_WIDTH : DESKTOP_BUTTON_WIDTH)) /
        2
      y = -260
    } else if (mode === 9) {
      x = itemPos9[i].x
      y = itemPos9[i].y
    } else {
      x = itemPos[i].x
      y = itemPos[i].y
    }
    const style = { transform: `translate(${x}px, ${y}px)` }
    return (
      <Link
        to="/flavors/#flavors"
        style={style}
        className={`item empty p${i + 1}`}
        key={i}
      >
        <img src="/empty-pack.png" alt="Empty Pint" />
      </Link>
    )
  })

  return (
    <div ref={domRef} className={`sized-${mode} bothHolder`}>
      <div className="empties">{empties}</div>
      <div className="fulls">
        <Pint
          item={items[0]}
          i={0}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[1]}
          i={1}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[2]}
          i={2}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[3]}
          i={3}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[4]}
          i={4}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[5]}
          i={5}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[6]}
          i={6}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[7]}
          i={7}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
        <Pint
          item={items[8]}
          i={8}
          itemPos={itemPos}
          itemPos9={itemPos9}
          dispatch={dispatch}
          flavorKeys={flavorKeys}
          mode={mode}
          container={container}
        />
      </div>
    </div>
  )
}

const Packs = ({ pack, dispatch }) => {
  const [container, setContainer] = useState({})
  const [hasMounted, setHasMounted] = useState(false)
  const ref = useRef(null)
  const [flavorKeys, setFlavorKeys] = useState({})
  const data = useStaticQuery(getCart)
  const flavors = data.flavors.edges.map((e) => e.node.acf.flavor)

  const mode = pack.mode || 9
  const error = pack.error
  const fields = data.page.edges[0].node.acf
  const bc = fields.bottom_cart
  const cyoPacks = data.page.edges[0].node.acf.cyo_packs.map((p) => p.pack)
  const ninePack = cyoPacks.filter((b) => b.number_pints === "9")[0]
  const fivePack = cyoPacks.filter((b) => b.number_pints === "5")[0]
  fivePack.price = parseFloat(fivePack.price)
  ninePack.price = parseFloat(ninePack.price)
  if (fivePack.image.localFile) {
    fivePack.image = fivePack.image.localFile.childImageSharp.fixed.src
    ninePack.image = ninePack.image.localFile.childImageSharp.fixed.src
  }

  const handleResize = () => {
    setContainer(ref.current.getBoundingClientRect())
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      dispatch({ type: "CLOSE_PACK" })
    }, CART_AUTO_CLOSE)
  }

  useEffect(() => {
    let fk = {}
    flavors.map((f) => {
      const img = (new Image().src =
        f.carton_image.localFile.childImageSharp.fixed.src)
      const name = f.name
      if (img) {
        fk[name] = img
      }
    })
    window.addEventListener("resize", handleResize)
    handleResize()
    // This is a dumb hack to get it to change on load.
    // because Redux doesn't rerender on page load in production.
    // And the SSR leaves it in a bad looking place.
    dispatch({ type: "SET_MODE", mode: mode === 5 ? 9 : 5 })
    dispatch({ type: "SET_MODE", mode: mode === 9 ? 5 : 9 })
    dispatch({ type: "SET_MODE", mode })
    setFlavorKeys(fk)
    setHasMounted(true) // I don't think this works.
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const theme = useContext(ThemeContext)
  let { items, open } = pack
  const len = items.length
  let disabled
  const product = items.length <= 5 ? fivePack : ninePack
  if (len < 5 || (len > 5 && len < 9)) {
    disabled = true
  }
  if (len === 5 || len === 9) {
    disabled = false
  }
  const wording = `Add ${mode} ${bc.add_pints_wording}`
  const addedPrice =
    items && items.length > 0
      ? items.reduce((a, c) => {
          return a + parseFloat(c.price)
        }, 0.0)
      : 0
  return (
    <PacksStyled
      open={open}
      className={`${hasMounted && open ? "open" : ""}`}
      onMouseOver={(e) => {
        clearTimeout(timeout)
      }}
      onMouseOut={(e) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          dispatch({ type: "CLOSE_PACK" })
        }, CART_AUTO_CLOSE)
      }}
    >
      <div
        className="packTopper"
        onClick={(e) => {
          open
            ? dispatch({ type: "CLOSE_PACK" })
            : dispatch({ type: "OPEN_PACK" })
        }}
      >
        <span>{bc.tab_title}</span>
        <SVG name="ChevDown" color={theme.Blue} className="svg" />
      </div>
      <div className="packArea">
        <div className="topLine">
          <Switch
            mode={mode}
            dispatch={dispatch}
            labels={[fivePack.sub_title, ninePack.sub_title]}
          />
          <p>{error ? error : wording}</p>
        </div>

        <div className={`midLine ${open ? "open" : ""}`}>
          <Items
            mode={mode}
            items={items}
            container={container}
            flavorKeys={flavorKeys}
            dispatch={dispatch}
            domRef={ref}
          />
          <div className="buttonHolder">
            <div className="price">${addedPrice.toFixed(2)}</div>
            <div className="flatRate">{bc.shipping_price_wording}</div>
            <Button
              className="addButton"
              onClick={(e) => {
                product.pints = items
                product.price = items
                  .reduce((a, c) => a + parseFloat(c.price), 0)
                  .toFixed(2)
                dispatch({ type: "ADD_TO_CART", product })
                dispatch({ type: "CLEAR_PACK" })
              }}
              disabled={disabled}
            >
              {bc.add_to_cart_button_label}
            </Button>
            <a
              style={{
                color: "#333",
              }}
              className="sh"
              href="/shipping-returns/"
              target="_blank"
            >
              {bc.shipping_info_link}
            </a>
          </div>
        </div>
        <div className="botLine">
          {bc.bottom_wording}&nbsp;
          <Link to="/flavors/#packs">{bc.bottom_link_text}</Link>
          &nbsp;{bc.bottom_wording2}
        </div>
      </div>
    </PacksStyled>
  )
}

const ConnectedPacks = connect((state) => state)(Packs)

const getCart = graphql`
  query {
    flavors: allWordpressWpFlavors {
      edges {
        node {
          acf {
            flavor {
              name
              carton_image {
                localFile {
                  childImageSharp {
                    fixed(height: 109, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    page: allWordpressPage(filter: { slug: { eq: "cart-page" } }) {
      edges {
        node {
          acf {
            bottom_cart {
              tab_title
              add_to_cart_button_label
              shipping_info_link
              shipping_price_wording
              add_pints_wording
              bottom_wording
              bottom_wording2
              bottom_link_text
            }
            cyo_packs {
              pack {
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 300) {
                        src
                      }
                    }
                  }
                }
                name
                sku
                productType
                sub_title
                description
                number_pints
              }
            }
          }
        }
      }
    }
  }
`

export default ConnectedPacks

const SwitchStyled = styled.div`
  position: relative;
  margin: 0px auto;
  height: 34px;
  border-radius: 60px;
  width: 323px;
  border: 2px solid ${(p) => p.theme.Grey};
  background: ${(p) => p.theme.White};
  font-size: 1.4em;
  display: flex;
  align-items: center;
  cursor: pointer;

  .highlight {
    position: absolute;
    background: ${(p) => p.theme.DarkBlue};
    transition: left 1s;
    z-index: 0;
    border-radius: 60px;
    height: inherit;
    height: 28px;
    top: 1px;
  }

  .text {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.sized-5 .highlight {
    width: 137px;
    left: 1px;
  }

  &.sized-9 .highlight {
    width: 181px;
    left: 137px;
  }

  &.sized-5 .fivePack {
    color: ${(p) => p.theme.White};
  }

  &.sized-9 .fivePack {
    color: ${(p) => p.theme.DarkBlue};
  }

  &.sized-5 .ninePack {
    color: ${(p) => p.theme.DarkBlue};
  }

  &.sized-9 .ninePack {
    color: ${(p) => p.theme.White};
  }

  .fivePack {
    width: 137px;
  }

  .ninePack {
    width: 186px;
  }
`

const Switch = ({ labels, mode, dispatch }) => {
  return (
    <SwitchStyled className={mode === 5 ? "sized-5" : "sized-9"}>
      <div className="highlight" />
      <div
        className="text fivePack"
        onClick={(e) => dispatch({ type: "SET_MODE", mode: 5 })}
      >
        {labels[0]}
      </div>
      <div
        className="text ninePack"
        onClick={(e) => dispatch({ type: "SET_MODE", mode: 9 })}
      >
        {labels[1]}
      </div>
    </SwitchStyled>
  )
}

import React from "react"
import styled from "styled-components"
import SVG from "../components/svg"

const RoughEdgeStyled = styled.div`
  position: absolute;
  height: 15px;
  width: 100%;
  z-index: 0;

  .edge {
    width: 1246px;
    height: 15px;
    position: absolute;
  }

  .top {
    top: -7px;
    z-index: 2;
  }

  .bottom {
    bottom: 7px;
    transform: scaleY(-1);
    z-index: 2;
  }

  .lefter {
    left: 50%;
    margin-left: -1245px;
  }

  .righter {
    right: 50%;
    margin-right: -1246px;
  }
`

const RoughEdge = ({ className, color }) => {
  const leftClasses = `lefter edge ${className}`
  const rightClasses = `righter edge ${className}`
  return (
    <RoughEdgeStyled>
      <SVG name="Edge" className={leftClasses} color={color} />
      <SVG name="Edge" className={rightClasses} color={color} />
    </RoughEdgeStyled>
  )
}

export default RoughEdge

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SVG from "./svg"

export const ButtonStyled = styled.button`
	background-color: ${(p) => p.theme.White};
  border-radius: 12px;
	font-weight: bold;
	letter-spacing: 1px;
	font-family: 'interstate-compressed';
  border: 3px solid ${(p) => p.theme.DarkBlue};
  padding: 7px 19px 7px 14px;
  font-size: 20px;
	display: inline-flex;
	align-items: center;
	color: ${(p) => p.theme.DarkBlue};
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	position: relative;
	transition: color 300ms, background-color 300ms;

	@media (max-width: 768px) {
		font-size: 20px;
	}

	.spinner {
    height: 30px;
    margin-right: 10px;
  }

	& > .svg {
		margin-left: 8px;
    display: inline-block;
    top: 0px;
		left: 8px;
    position: relative;
    height: 21px;
		color: ${(p) => p.theme.DarkBlue};
	}

	&.cart-button {
		background-color: ${(p) => p.theme.DarkBlue};
		color: ${(p) => p.theme.White};
		display: flex;
		justify-content: center;
		width: 100%;
		& > .svg {
			color: ${(p) => p.theme.White};
		}
	}

	@media (min-width: 768px) {
		&:hover {
			background-color: ${(p) => p.theme.DarkBlue};
			color: ${(p) => p.theme.White};
			& > .svg {
				color: ${(p) => p.theme.White};
			}
			& > .anim {
		    animation-name: moving-arrow;
		    animation-duration: 1.5s;
		    animation-iteration-count: infinite;
			}
		}
	}

	&:disabled {
		background: ${(p) => p.theme.MedGrey};
		color: ${(p) => p.theme.White};
		border-color: ${(p) => p.theme.MedGrey};
		pointer-events: none;
		& > .svg {
			color: ${(p) => p.theme.White};
			/* display: none; */
		}
	}
}
`

const ButtonLinkStyled = styled(Link)`
  background-color: ${(p) => p.theme.White};
  border-radius: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "interstate-compressed";
  border: 3px solid ${(p) => p.theme.DarkBlue};
  padding: 7px 19px 7px 14px;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.DarkBlue};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: color 300ms, background-color 300ms;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  .spinner {
    height: 30px;
    margin-right: 10px;
  }

  & > .svg {
    margin-left: 8px;
    display: inline-block;
    top: 0px;
    position: relative;
    height: 21px;
    color: ${(p) => p.theme.DarkBlue};
  }

  &.cart-button {
    background-color: ${(p) => p.theme.DarkBlue};
    color: ${(p) => p.theme.White};
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    & > .svg {
      color: ${(p) => p.theme.White};
    }
  }
  @media (min-width: 768px) {
    &:hover {
      background-color: ${(p) => p.theme.DarkBlue};
      color: ${(p) => p.theme.White};
      & > .svg {
        color: ${(p) => p.theme.White};
      }
      & > .anim {
        animation-name: moving-arrow;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
    }
  }
  &:disabled {
    background: ${(p) => p.theme.Grey};
    color: ${(p) => p.theme.White};
    border-color: ${(p) => p.theme.Grey};
    pointer-events: none;
    & > .svg {
      color: ${(p) => p.theme.White};
    }
  }
`

const ButtonAnchorStyled = styled.a`
  background-color: ${(p) => p.theme.White};
  border-radius: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "interstate-compressed";
  border: 3px solid ${(p) => p.theme.DarkBlue};
  padding: 7px 19px 7px 14px;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.DarkBlue};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: color 300ms, background-color 300ms;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  .spinner {
    height: 30px;
    margin-right: 10px;
  }

  & > .svg {
    margin-left: 8px;
    display: inline-block;
    top: 0px;
    position: relative;
    height: 21px;
    color: ${(p) => p.theme.DarkBlue};
  }

  &.cart-button {
    background-color: ${(p) => p.theme.DarkBlue};
    color: ${(p) => p.theme.White};
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    & > .svg {
      color: ${(p) => p.theme.White};
    }
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: ${(p) => p.theme.DarkBlue};
      color: ${(p) => p.theme.White};
      & > .svg {
        color: ${(p) => p.theme.White};
      }
      & > .anim {
        animation-name: moving-arrow;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
    }
  }

  @media (max-width: 768px) {
    &:disabled {
      background: ${(p) => p.theme.Grey};
      color: ${(p) => p.theme.White};
      border-color: ${(p) => p.theme.Grey};
      pointer-events: none;
      & > .svg {
        color: ${(p) => p.theme.White};
      }
    }
  }
`

export default (props) => {
  let { children, href, to, link, ...rest } = props
  let iconHTML
  switch (rest.icon) {
    case "+":
      iconHTML = <SVG name="Plus" className="svg" />
      break
    case "-":
      iconHTML = ""
      break
    default:
      iconHTML = <SVG name="Arrow" className="svg anim" />
      break
  }
  if (link) {
    if (link.match("http")) {
      href = link
    } else {
      to = link
    }
  }
  if (to) {
    if (to[to.length - 1] != "/") to += "/"
    return (
      <ButtonLinkStyled {...rest} to={to}>
        {children}
        {iconHTML}
      </ButtonLinkStyled>
    )
  } else if (href) {
    const target = {
      target: "_blank",
      rel: "noopener noreferrer",
    }
    return (
      <ButtonAnchorStyled {...rest} href={href} {...target}>
        {children}
        {iconHTML}
      </ButtonAnchorStyled>
    )
  } else {
    return (
      <ButtonStyled {...rest}>
        {children}
        {iconHTML}
      </ButtonStyled>
    )
  }
}

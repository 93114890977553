import React from "react"
import SVG from "./svg"
import styled from "styled-components"
import ButtonStyled from "./button"

const FormButtonStyled = styled.button`
  background: ${p => p.theme.DarkBlue};
  border: none;
  width: 100%;
  max-width: ${p => p.width || "100%"};
  display: block;
  cursor: pointer;
  height: 50px;
  font-size: 2em;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-family: "interstate-compressed";
  justify-content: center;
  color: ${p => p.theme.White};

  &:active {
    background: ${p => p.theme.VDarkBlue};
  }

  .spinner {
    height: 30px;
    margin-right: 10px;
  }
`

const FormButton = ({ children, onClick, loading, white, width }) => {
  const loadHTML = loading ? <SVG name="Loading" className="spinner" /> : ""
  const Styled = white ? ButtonStyled : FormButtonStyled
  return (
    <Styled
      width={width}
      disabled={loading ? "disabled" : ""}
      onClick={e => {
        if (!loading && onClick) {
          onClick(e)
        }
      }}
    >
      {loadHTML}
      {children}
    </Styled>
  )
}

export default FormButton

module.exports = (base, node) => {
  if (typeof node === "undefined" || node === null) return base
  const title = node.title ? node.title : node
  let transformed = title
    .trim()
    .replace(/&/g, "and")
    .replace(/È/gi, "e")
    .replace(/\s/gi, "-")
    .replace(/[^A-Z0-9-]/gi, "")
    .toLowerCase()
  if (transformed[transformed.length - 1] !== "/") transformed += "/"
  return `${base}${transformed}`
}

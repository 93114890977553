import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"
import "./animations.css"
import styled, { ThemeProvider } from "styled-components"
import ConnectedNavigation from "./nav"
import MailingList from "./mailing-list"
import Footer from "./footer"
import Button from "./button"
import SEO from "./seo"
import { NavigationContext } from "./navigation-context"
import { getCookie } from "../utils/cookies"
import { getColors } from "../colors"
import ConnectedSideCart from "./side-cart"
import ConnectedPackCart from "./pack-cart"
import throttle from "../utils/throttle"

const CookieAgreementStyled = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 6;
  width: 360px;
  padding: 20px;
  background: ${(p) => p.theme.LightBlue};
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  color: ${(p) => p.theme.DarkBlue};

  h2 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    text-align: center;
    font-size: 2.4em;
    line-height: 24px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  p {
    font-size: 1em;
    margin: 20px 0;
  }

  a {
    color: ${(p) => p.theme.DarkBlue};
    font-weight: bold;
  }
  .buttonCont {
    width: 290px;
    padding-left: 20px;
  }

  .close {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border-radius: 100%;
    background-color: ${(p) => p.theme.White};
    color: ${(p) => p.theme.DarkBlue};
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
    top: -14px;
    right: -14px;

    &:hover {
      background: ${(p) => p.theme.LightBlue};
    }
  }

  @media (max-width: 768px) {
    width: 100vw;
    right: 0;
    bottom: 0;
    flex-wrap: wrap;

    .close {
      top: 0;
      right: 0;
      border-radius: 0;
    }
  }
`

const CookieAgreement = ({ fields, dispatch, user }) => {
  if (user.cookiesAgreed || user.cookiesClosed) return ""
  return (
    <CookieAgreementStyled>
      <div
        className="close"
        onClick={(e) => dispatch({ type: "COOKIES_CLOSED" })}
      >
        &times;
      </div>
      <h2
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: fields.cookies_agreement.headline,
        }}
      />
      <p
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: fields.cookies_agreement.statement,
        }}
      />
      <Button
        className="cart-button"
        icon="-"
        onClick={(e) => dispatch({ type: "COOKIES_AGREED" })}
      >
        {fields.cookies_agreement.agree_label}
      </Button>
    </CookieAgreementStyled>
  )
}

const ConnectedCookieAgreement = connect((state) => state)(CookieAgreement)

const LayoutWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.bgColor};
  height: ${(p) => (p.navigationOpen ? "100vh" : "")};
  overflow: ${(p) => (p.navigationOpen ? "hidden" : "")};
  scrollbar-width: none;
  @media (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
  }

  .skip {
    position: absolute;
    left: -9999px;

    &:focus {
      left: 10px;
    }
  }
`

const Layout = (props) => {
  const children = props.children
  const [navigationOpen, setNavigationOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(
    typeof window !== `undefined` ? window.scrollY : 0
  )

  const palette = getColors("")
  const mainTheme = {
    ...palette,
    PrimaryColor: palette.Blue,
    BgColor: palette.BG,
    Blue: palette.MedBlue,
    scrollPosition,
  }

  let features, to
  useEffect(() => {
    // The Draw in Wave Effect
    features = document.querySelectorAll(".swave")
    let wh
    let length = 175
    const handleScroll = () => {
      return throttle(() => {
        const wh = window.innerHeight || document.documentElement.clientHeight
        const scrolled =
          window.pageYOffset || document.documentElement.scrollTop
        Array.from(features).map((f) => {
          const bounding = f.getBoundingClientRect()
          const { top, bottom } = bounding,
            vpt = top - 100 <= 0,
            vpb = bottom >= wh
          if (!vpt && !vpb) {
            const percent = (top - 100) / wh
            f.style.strokeDasharray = length
            f.style.strokeDashoffset = length * percent
          }
        })
      })
    }

    const handleHashChange = function (event) {
      var element = document.getElementById(window.location.hash.substring(1))
      if (element) {
        if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
          element.tabIndex = -1
        }
        element.focus()
      }
    }

    window.addEventListener("scroll", handleScroll(), false)
    window.addEventListener("resize", handleScroll(), false)
    window.addEventListener("hashchange", handleHashChange, false)

    const active = document.querySelector(".navItems .active")
    if (active) active.classList.add("animate")

    return () => {
      window.removeEventListener("resize", handleScroll())
      window.removeEventListener("scroll", handleScroll())
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      page: allWordpressPage(filter: { slug: { eq: "shared-layout" } }) {
        edges {
          node {
            acf {
              header {
                promo_banner
                logo {
                  source_url
                }
                account_label
              }
              mailing_list {
                headline
                sub_heading
                input_placeholder
                button_label
                form_submit_success_message
                featured_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 700, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              social {
                alt_text
                icon {
                  source_url
                }
                link
              }
              cookies_agreement {
                headline
                statement
                agree_label
              }
              footer {
                copyright
                logo {
                  source_url
                }
                footer_links {
                  display_text
                  link_location
                }
                available_title
                available_at {
                  icon {
                    url {
                      source_url
                    }
                  }
                  alt_text
                }
              }
            }
          }
        }
      }
    }
  `)

  const node = data.page.edges[0].node
  const fields = node.acf
  const agreed_already =
    getCookie("cookiesAgreed") || getCookie("cookiesClosed")

  return (
    <NavigationContext.Provider
      value={{
        navigationOpen: navigationOpen,
        toggleNavigation: () => {
          setNavigationOpen(!navigationOpen)
        },
      }}
    >
      <ThemeProvider theme={mainTheme}>
        <LayoutWrapper navigationOpen={navigationOpen}>
          <SEO title="Nadamoo - " />
          <a href="#content" className="skip">
            Skip navigation
          </a>
          <ConnectedNavigation />
          <main id="content">{children}</main>
          <MailingList fields={fields} />
          <Footer fields={fields} />
          <ConnectedPackCart />
          <ConnectedSideCart />{" "}
          {agreed_already ? "" : <ConnectedCookieAgreement fields={fields} />}
        </LayoutWrapper>
      </ThemeProvider>
    </NavigationContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

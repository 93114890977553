import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"

const CartIconStyled = styled.div`
  position: relative;
  cursor: pointer;
  height: 22px;
  width: 22px;

  .cart-item-number {
    display: block;
    font-family: "Montserrat";
    position: absolute;
    top: -4px;
    right: -20px;
    background-color: ${(p) => p.theme.DarkBlue};
    color: ${(p) => p.theme.White};
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 13px;
  }
`

const CartIcon = ({ onClick, sideCartOpen, cart, dispatch }) => {
  const numItems = cart.items.length
  return (
    <CartIconStyled
      onClick={(e) => {
        dispatch({ type: "TOGGLE_CART" })
      }}
    >
      <img src="/cart.svg" alt="Shopping Cart" />
      <span className="cart-item-number">{numItems}</span>
    </CartIconStyled>
  )
}
const ConnectedCartIcon = connect((state) => state)(CartIcon)

export default ConnectedCartIcon

export const getColors = (theme) => {
  let flavors = {
    Banana1: "#f2cd00",
    Banana2: "#C67A38",
    Cake1: "#8DC6E8",
    Cake2: "#F49DC0",
    ColdBrew1: "#ECA65D",
    ColdBrew2: "#603717",
    PeanutButter1: "#E55524",
    PeanutButter2: "#FEC970",
    Chocolate1: "#B3885E",
    Chocolate2: "#472917",
    CookieDough1: "#5F462C",
    CookieDough2: "#E5C88F",
    CookieCream1: "#2C2925",
    CookieCream2: "#EBDDB7",
    MaplePecan1: "#D54123",
    MaplePecan2: "#F16931",
    Marshmellow1: "#B4ACD4",
    Marshmellow2: "#EF3256",
    MintChip1: "#57C1A5",
    MintChip2: "#56291A",
    Peach1: "#FCB05C",
    Peach2: "#E45A25",
    Pistachio1: "#B6D77A",
    Pistachio2: "#7B593F",
    RockyRoad1: "#E3D6C5",
    RockyRoad2: "#6D432C",
    Strawberry1: "#F59AB1",
    Strawberry2: "#DE252C",
    Vanilla1: "#EAE097",
    Vanilla2: "#B88F38",
  }

  let defaultColors = {
    Black: "#000",
    White: "white",
    Red: flavors.Strawberry2,
    VDarkBlue: "#00285C",
    DarkBlue: "#1B4A8A",
    MedBlue: "#3574ac",
    MedABlue: "#9EC4E0",
    LightBlue: "#B7D3DB",
    BGBlue: "#f1f6f8",
    Grey: "#aaa",
    MedGrey: "#737373",
    DarkGrey: "#333",
    Green: "#BAD88B",
    Pink: "#FFA3A6",
    Orange: "#F6BF7D",
    LightestBlue: "#e9f1f4",
    ...flavors,
  }

  return defaultColors
}

import React from "react"
import styled from "styled-components"
import Button from "./button"
import Img from "gatsby-image"
import PromoSticker from "./promo-sticker"

const FeaturedMerchStyled = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 155px 200px;
  grid-gap: 16px;
  background-color: ${(p) => p.theme.LightestBlue};
  max-width: 400px;
  height: 0px;
  opacity: 0;
  transition: opacity 1s, height 1s;

  &.show {
    height: 155px;
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: auto;
    grid-template-columns: 155px 1fr;
  }

  .close {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    z-index: 3;
    cursor: pointer;

    @media (min-width: 769px) {
      bottom: 10px;
      right: 10px;
      &:hover {
        color: ${(p) => p.theme.Blue};
      }
    }
  }

  .image {
    width: 155px;
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    .img {
      width: 155px;
      height: 155px;
    }

    @media (max-width: 768px) {
      width: 155px;
      height: 155px;
      img {
        max-width: 155px;
      }
    }
  }

  .info {
    color: ${(p) => p.theme.DarkGrey};
    font-size: 1em;
  }

  h3 {
    color: ${(p) => p.theme.DarkBlue};
    font-family: "interstate-compressed";
    text-transform: uppercase;
    margin: 10px 0 3px 0;

    @media (max-width: 768px) {
      font-size: 1.8em;
    }
  }

  .addButton {
    margin-top: 10px;

    @media (max-width: 768px) {
      margin-top: 5px;
    }
  }

  .sub-head {
    margin: 0;
    text-transform: uppercase;
    line-height: 1em;
    color: ${(p) => p.theme.Blue};
  }

  p.description {
    padding: 0 10px 0 0;
    margin: 0;
    font-size: 1em;
    line-height: 1.2em;
    color: ${(p) => p.theme.DarkGrey};
  }
`

const FeaturedMerch = ({ product, dispatch, hide }) => {
  const { name, price, sku, name_with_html, promotion } = product
  const image = product.images[0].image.localFile.childImageSharp.fluid
  const description = product.short_info
  return (
    <FeaturedMerchStyled className={hide ? "" : "show"}>
      <div className="image">
        <Img fluid={image} alt={name} className="img" />
        <PromoSticker promotion={promotion} className="topLeftInside" />
      </div>
      <div className="info">
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: name_with_html || name,
          }}
        />
        <p className="description">{description}</p>
        <div
          className="close"
          onClick={(e) => dispatch({ type: "HIDE_FEATURED_UPSELL" })}
        >
          &times;
        </div>
        <Button
          className="addButton"
          onClick={(e) => {
            dispatch({ type: "HIDE_FEATURED_UPSELL" })
            dispatch({
              type: "ADD_TO_CART",
              product: {
                name,
                image: image.src,
                price,
                sku,
              },
            })
          }}
        >
          Add To Cart
        </Button>
      </div>
    </FeaturedMerchStyled>
  )
}

export default FeaturedMerch

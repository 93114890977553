import React, { useState } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import Status from "./status"
import ResponsiveSpacing from "./responsive-spacing"
import Img from "gatsby-image/withIEPolyfill"
import Inner from "./inner"
import Input from "./inputs"
import FormButton from "./form-button"
import EMAIL_REGEXP from "../utils/email-regexp"
import HOST from "../utils/host"

const errorMsgs = {
  email: "E-Mail Address is Invalid",
}

const MailingListStyled = styled.div`
  position: relative;
  z-index: 0;
  height: 280px;
  color: ${(props) => props.theme.DarkBlue};
  background-image: url("/mailing-list-bg.svg");
  background-size: cover;
  background-color: #cadfe4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    height: 490px;
    padding: 50px 0px;
  }

  .gatsby-image-wrapper {
    height: 300px;
    width: 352px;
  }

  .inputCont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 582px;

    .input {
      min-width: 382px;
      margin: 30px 10px 0 0;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      max-width: calc(100vw - 30px);
      .input {
        min-width: 0;
      }
    }
  }

  h3 {
    margin: 0px;
    font-size: 6em;
    font-weight: 800;
    letter-spacing: 2px;
    font-family: "interstate-compressed";
    @media (max-width: 768px) {
      margin: 0;
    }
  }

  p {
    margin: 10px 0;
    font-size: 1.4em;
    line-height: 1.2em;
    font-family: "Montserrat";
  }

  .decoration {
    position: absolute;
    top: -20px;
    right: 0;
    width: 352px;
    height: 290px;
    z-index: 0;
    @media (max-width: 768px) {
      top: auto;
      bottom: 0;
      right: 0;
    }
  }
`

const MailingList = ({ fields }) => {
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm()

  const {
    headline,
    sub_heading,
    input_placeholder,
    button_label,
    featured_image,
    form_submit_success_message,
  } = fields.mailing_list

  const required = (name) => {
    if (!errors[name]) return
    if (errors[name].message) return errors[name].message
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
  }

  const onSubmit = (data) => {
    setLoading(true)
    const postOpts = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        credentials: "include",
      },
      method: "POST",
      body: JSON.stringify(data),
    }
    fetch(HOST + "/mailing-list-signup", postOpts)
      .then((res) => {
        if (res.ok) {
          setLoading(false)
          setStatus({ code: 1, msg: form_submit_success_message })
          reset()
        }
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
        console.log(err)
      })
  }

  return (
    <MailingListStyled>
      <ResponsiveSpacing>
        <Inner maxWidth="1400px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Status
              status={status}
              className="float-right"
              setStatus={setStatus}
            />
            <h3>{headline}</h3>
            <p>{sub_heading}</p>
            <div className="inputCont">
              <Input
                placeholder={input_placeholder}
                className="input"
                id="ml_email"
                name="email"
                type="email"
                error={required("email")}
                register={register}
                registerValue={{
                  required: "Required",
                  pattern: {
                    value: EMAIL_REGEXP,
                    message: errorMsgs.email,
                  },
                }}
              />
              <FormButton loading={loading} white={true}>
                {loading ? "PROCESSING" : button_label}
              </FormButton>
            </div>
          </form>
        </Inner>
      </ResponsiveSpacing>
      <div className="decoration">
        {featured_image && featured_image.localFile ? (
          <Img fluid={featured_image.localFile.childImageSharp.fluid} alt="" />
        ) : (
          ""
        )}
      </div>
    </MailingListStyled>
  )
}

export default MailingList

import React, { useState } from "react"
import styled from "styled-components"

const InputStyled = styled.div`
  position: relative;
  margin: 20px 0 20px 0;

  @media (max-width: 768px) {
    padding: 2px 0;
  }

  .error {
    visibility: ${(p) => (p.error ? "visible" : "hidden")};
    color: ${(p) => p.theme.Red};
    min-height: 20px;
    font-size: 1.4em;
    display: flex;
    align-items: center;
  }

  label {
    position: absolute;
    color: ${(props) => props.theme.DarkBlue};
    font-family: "interstate-compressed";
    text-transform: uppercase;
    transition: left 400ms, top 400ms, font-size 400ms;
    font-size: 1.8em;
    user-select: none;
    font-weight: bold;
    z-index: 0;
    top: 6px;
    left: 6px;

    @media (max-width: 768px) {
      /* width: 100%; */
    }
  }

  &.focused label {
    left: 4px;
    top: -20px;
    font-size: 1.4em;
    @media (max-width: 768px) {
      left: 0;
      top: -16px;
    }
  }

  input {
    position: relative;
    z-index: 1;
    font-size: 1.8em;
    background: transparent;
    border: 0;
    height: 30px;
    width: 100%;
    outline: none;
    color: ${(props) => props.theme.DarkBlue};
    border-bottom: 3px solid ${(p) => p.theme.DarkBlue};
    border-radius: 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

const Input = ({
  id,
  name,
  value = "",
  placeholder,
  register,
  registerValue,
  type = "text",
  error,
  onBlur,
  className,
  onChange,
}) => {
  const [focused, setFocus] = useState(false)
  const focusClass = focused ? "focused" : ""
  return (
    <InputStyled error={error} className={`${focusClass} ${className}`}>
      <label htmlFor={id || name}>{placeholder}</label>
      <input
        onChange={onChange}
        name={name}
        id={id || name}
        type={type}
        ref={register && register(registerValue)}
        onFocus={(e) => setFocus(true)}
        onBlur={(e) => {
          if (!e.target.value) setFocus(false)
        }}
      />
      <div className="error">{error}</div>
    </InputStyled>
  )
}

export default Input

import React from "react"
import styled from "styled-components"

const StatusStyled = styled.div`
  color: ${(p) => (p.code ? p.theme.MintChip2 : p.theme.Strawberry2)};
  border: 4px solid
    ${(p) => (p.code ? p.theme.Pistachio1 : p.theme.Strawberry2)};
  border-radius: 12px;
  background: ${(p) => p.theme.White};
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 8px;
  min-height: 40px;
  position: relative;
  z-index: 10;

  &.float-right {
    width: 300px;
    float: right;
    margin-right: 40px;
    box-shadow: 4px 4px rgba(0, 0, 0, 0.4);
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: ${(p) => p.theme.DarkGrey};
    border-radius: 100%;
    z-index: 3;
    cursor: pointer;

    &:hover {
      background: ${(p) => p.theme.White};
    }
  }

  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${(p) =>
      p.code ? p.theme.Pistachio1 : p.theme.Strawberry1};
  }
  .msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 40px 10px 10px;
    font-size: 1.4em;
    line-height: 1.2em;
  }
`

const Status = ({ status, className, setStatus }) => {
  let { code, msg } = status
  if (!status) return ""
  if (code === "UNIDENTIFIED_CUSTOMER") {
    code = 0
    msg = "Login unsuccessful.  Have you signed up yet?"
  }
  return (
    <StatusStyled code={code} className={className}>
      <div className="close" onClick={(e) => setStatus("")}>
        &times;
      </div>
      <div className="code" />
      <div
        className="msg"
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: msg,
        }}
      />
    </StatusStyled>
  )
}

export default Status

import React from "react"
import styled from "styled-components"

const PromoStickerStyled = styled.div`
  z-index: 2;
  position: relative;

  &.smaller {
    transform: scale(0.6);
  }

  &.topLeft {
    position: absolute;
    top: -${(p) => p.size / 3}px;
    left: -${(p) => p.size / 2}px;
  }

  &.topLeftSmall {
    position: absolute;
    top: -20px;
    left: -30px;
  }

  &.topLeftInside {
    z-index: 2;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  img {
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
  }
`

const PromoSticker = ({ promotion, size = 70, ...rest }) => {
  if (!promotion) return ""
  const promoSticker = `/promo-${promotion}.png`
  return (
    <PromoStickerStyled size={size} {...rest}>
      <img src={promoSticker} alt={promotion} />
    </PromoStickerStyled>
  )
}

export default PromoSticker

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import ConnectedCartIcon from "./cart-icon"
import CartItem from "./cart-items"
import Status from "./status"
import FormButton from "./form-button"
import FeaturedMerch from "./featured-merch"
import { createCartAction } from "../reducers/cart"

const cartQuery = graphql`
  query {
    page: allWordpressPage(filter: { slug: { eq: "cart-page" } }) {
      edges {
        node {
          acf {
            cart_info {
              short_heading
              checkout_button_label
              subtotal_wording
              empty_cart_wording
            }
            shipping_info {
              flat_rate
            }
            upsell_merch {
              wordpress_id
            }
            cyo_packs {
              pack {
                name
                sub_title
                description
                productType
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 185) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    merch: allWordpressWpMerch {
      edges {
        node {
          id
          wordpress_id
          title
          acf {
            name
            sku
            promotion
            price
            short_info
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 155, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const HeaderStyled = styled.div`
  position: fixed;
  top: 8px;
  width: 410px;
  z-index: 2;
  background: ${(p) => p.theme.White};
  border-bottom: 2px solid ${(p) => p.theme.Grey};
  padding: 4px 0;
  text-align: center;
  margin: 0;
  color: ${(p) => p.theme.DarkBlue};

  h2 {
    font-family: "interstate-compressed";
    margin: 0;
    font-size: 3.2em;
    font-weight: 800;
    display: flex;
    justify-content: center;
  }

  .cart-icon-header {
    position: absolute;
    left: 50%;
    top: 15px;
    margin-left: 28px;
  }

  .fullScreen {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    height: 40px;
    text-decoration: none;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 1.4em;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .close {
    padding: 6px;
    height: 40px;
    width: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 5px;
    font-size: 50px;
    border-radius: 6px;

    @media (max-width: 768px) {
      right: 0;
    }

    &:hover {
      background: ${(p) => p.theme.LightBlue};
      cursor: pointer;
    }
  }
`

const Header = ({ fields, close }) => {
  return (
    <HeaderStyled>
      <Link to="/cart/" className="fullScreen">
        Full Screen
      </Link>
      <h2>{fields.cart_info.short_heading}</h2>
      <div className="close" onClick={close}>
        &times;
      </div>
    </HeaderStyled>
  )
}

const SideCartStyled = styled.div`
  position: fixed;
  overflow: hidden;
  bottom: 0;
  top: 90px;
  width: 100%;
  font-family: "Montserrat";
  max-width: 420px;
  right: -420px;
  transform: translateX(0px);
  transform: translateX(${(p) => (p.sideCartOpen ? "-420px" : "0px")});
  transition: transform 0.3s;
  align-items: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.3);
  background: ${(p) => p.theme.White};
  z-index: 4;
  padding: 12px;

  @media (max-width: 768px) {
    padding: 12px 0;
    width: 100vw;
  }
`

const CartBottomStyled = styled.div`
  background: ${(p) => p.theme.White};
  position: fixed;
  padding: 0 0 10px 0;
  bottom: 0px;
  right: 10px;
  left: 10px;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 1px 0px 10px 0px;
  }
`

const CartBottom = ({ children }) => {
  return <CartBottomStyled>{children}</CartBottomStyled>
}

const ScrollerStyled = styled.div`
  position: fixed;
  top: 62px;
  bottom: ${(p) => (p.taller ? "100px" : "290px")};
  left: 10px;
  right: 10px;
  padding: 0;
  z-index: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 768px) {
    top: 57px;
    bottom: 93px;
    width: auto;
    padding: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .noItems {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: ${(p) => p.theme.Blue};
  }

}
`

const TotalsStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
  padding: 10px 10px 10px 0;
  border-top: 1px solid ${(p) => p.theme.Grey};
  color: ${(p) => p.theme.DarkBlue};
  font-size: 18px;

  @media (max-width: 768px) {
    float: none;
    grid-gap: 0px;
    padding: 5px 0px 5px 0;
  }

  .subTotal {
    text-transform: uppercase;
  }
  .subNumber {
    margin: 0;
    text-transform: uppercase;
    text-align: right;
  }
`

const Totals = ({ fields, total }) => {
  return (
    <TotalsStyled>
      <div className="subTotal">{fields.cart_info.subtotal_wording}</div>
      <div className="subNumber">${total}</div>
    </TotalsStyled>
  )
}

const Scroller = ({ children, taller }) => {
  return <ScrollerStyled taller={taller}>{children}</ScrollerStyled>
}

const SideCart = ({ dispatch, cart, sideCartOpen, setSideCartOpen }) => {
  const [hasMounted, setHasMounted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")

  useEffect(() => {
    dispatch({ type: "CLOSE_CART" })
    setHasMounted(true)
  }, [])
  const data = useStaticQuery(cartQuery)

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
      2
    )
  }
  const fields = data.page.edges[0].node.acf
  const merch = data.merch.edges.map((e) => e.node)

  let { items, open, hideUpsell } = cart

  let total = items.reduce((a, c) => {
    const p = parseFloat(c.price)
    return p + a
  }, 0)

  total = round(total, 2)
  const cartItems = items.map((pr, i) => (
    <CartItem dispatch={dispatch} key={i} index={i} product={pr} />
  ))

  const upsellIds = fields.upsell_merch.map((m) => m.wordpress_id)
  const featured = merch.filter((n) => upsellIds.includes(n.wordpress_id))[0]
    .acf
  total = round(total, 2)

  // true if upsell merch is already in cart.
  const alreadyGotIt = items.map((i) => i.sku).includes(featured.sku)
  const noItemsHTML =
    items.length === 0 ? (
      <div className="noItems" tabIndex="0">
        {fields.cart_info.empty_cart_wording}
      </div>
    ) : (
      ""
    )

  if (!hasMounted) {
    return ""
  }

  const hide = hideUpsell || alreadyGotIt || featured.promotion === "sold-out"
  return (
    <SideCartStyled sideCartOpen={open}>
      <Status status={status} className="float-right" setStatus={setStatus} />
      <Header
        fields={fields}
        close={(e) => {
          setLoading(false)
          dispatch({ type: "CLOSE_CART" })
        }}
      />
      <Scroller taller={hide}>
        <div>{cartItems}</div>
        {noItemsHTML}
      </Scroller>
      <CartBottom>
        <FeaturedMerch
          dispatch={dispatch}
          product={featured}
          closeable={true}
          hide={hide}
        />
        <Totals fields={fields} total={total} />
        <FormButton
          loading={loading}
          onClick={(e) => {
            if (items.length == 0) return
            setLoading(true)
            dispatch(createCartAction()).then((json) => {
              if (json.errors) {
                console.log("ERROR", json.errors)
                setStatus({ code: 0, msg: json.errors[0].message })
                setLoading(false)
                return
              }
              if (json.data.checkoutCreate) {
                dispatch({ type: "CLEAR_CART_BACKUP" })
                setStatus("")
                setLoading(false)
                window.location.href = json.data.checkoutCreate.checkout.webUrl
              }
            })
          }}
        >
          {loading ? "PROCESSING" : fields.cart_info.checkout_button_label}
        </FormButton>
      </CartBottom>
    </SideCartStyled>
  )
}

const ConnectedSideCart = connect((state) => state)(SideCart)

export default ConnectedSideCart

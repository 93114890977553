import React from "react"
import styled from "styled-components"

const ResponsiveSpacingStyled = styled.div`
  transition: padding 600ms;
  position: relative;
  padding: 0;
  width: 100%;
  @media (max-width: 1450px) {
    padding: 0px 50px;
  }
  @media (max-width: 768px) {
    padding: 0 50px;
    margin: 0;
  }
  @media (max-width: 580px) {
    padding: 0px 10px;
  }
`

const ResponsiveSpacing = ({ children }) => {
  return <ResponsiveSpacingStyled>{children}</ResponsiveSpacingStyled>
}

export default ResponsiveSpacing

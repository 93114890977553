import React from "react"
import styled from "styled-components"

const InnerStyled = styled.div`
  max-width: ${p => p.maxWidth || "1400px"};
  width: 100%;
  margin: ${p => p.margin || "0 auto"};
  min-height: ${p => p.height || 0};
  padding: ${p => p.spacing || "0"};
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: ${p => p.mobileMargin || "0 auto"};
    padding: ${p => p.mobileSpacing || "0"};
  }
`

const Inner = props => {
  const { children, ...rest } = props
  return <InnerStyled {...rest}>{children}</InnerStyled>
}

export default Inner
